import { DateTime } from "luxon";
import { GetBuyerSalesCapDetails } from "../common/models/types/GetBuyerSalesCapDetails";
import { GetSalesScreens } from "../common/models/types/GetSalesScreens";
import { getStorage } from "../common/utils/storage";

interface SalesQueryDataCache<T> {
    data?: T;
    lastUpdated?: string;
    state: 'loading' | 'ready' | 'error';
    errorDetails?: any;
}

interface LoadedDataStorage {
    Summary?: SalesQueryDataCache<GetSalesScreens>;
    Daily?: SalesQueryDataCache<GetSalesScreens>;
    Hourly?: SalesQueryDataCache<GetSalesScreens>;
}

interface LoadedBuyerSalesCapacity {
    /** out trigger to initiate reloading buyer capcity data */
    mark: string;
    updatedAt?: number;
    date?: string;
    data?: GetBuyerSalesCapDetails;
    vertical?: number;
    subVertical?: number;
}

export interface salesScreenSearchStateProps {
    startDate: string; /** timestamp value in user's preferred timezone */
    subVerticalId: number;
    verticalId: number;
    accountManager:string | null;
    comparisonDayLookback: number;
    accountManagers?: string[];
    fetchEDW?: boolean;

    /** save previously loaded reports data */
    lastLoadedData?: LoadedDataStorage;
    /** out trigger to initiate reloading `lastLoadedData` values */
    lastLoadedMark?: string;

    /** save previously loaded buyer sales capacity data */
    loadedBuyerSalesCapacities: {
        [buyerName: string]: LoadedBuyerSalesCapacity;
    }
}

const initialSalesScreenState: salesScreenSearchStateProps = {
    startDate: '', //save unmodified endDate
    subVerticalId: 0,
    verticalId: 0,
    accountManager: null,
    comparisonDayLookback: 0,
    loadedBuyerSalesCapacities: {},
    fetchEDW: false
};

const SALES_SCREEN_SEARCH_SAVE = "SAVE";
const SALES_SCREEN_SEARCH_MARK = "SEARCH_MARK";
const SALES_SCREEN_ACCMANAGERS = "SET_ACC_MANAGERS";
const SALES_SCREEN_DATA_UPDATE = "SET_DATA_UPDATE";
const SALES_BUYER_CAP_DETAILS_TRIGGER = "TRIGGER_SALES_BUYER_CAP_DETAIL";
const SALES_BUYER_CAP_DETAILS_UPDATE = "SALES_BUYER_CAP_DETAILS_UPDATE";

const STORE_KEY = `${process.env.GATSBY_BRANCH}-salesScreenSearchStateProps`;

interface salesScreenSearchAction {
    type: string;
    payload: salesScreenSearchStateProps;
}

interface salesScreenSearchMarking {
    type: string;
    mark: string;
}

interface salesScreenAccountManagers {
    type: string;
    list: string[];
}

interface salesScreenDataUpdate {
    type: string;
    dataKey: string;
    dataValue: SalesQueryDataCache<GetSalesScreens>;
}

interface salesBuyerCapDetailMarking {
    type: string;
    mark: string;
    buyer: string;
    date: string, 
    vertical: number, 
    subVertical: number
}

interface salesBuyerCapDetailUpdate {
    type: string;
    buyer: string;
    data?: GetBuyerSalesCapDetails;
}

export const saveSalesScreenSearch = (payload: salesScreenSearchStateProps): salesScreenSearchAction => ({
    type: SALES_SCREEN_SEARCH_SAVE,
    payload,
});

export const setSalesScreenSearchMarking = (payload: string): salesScreenSearchMarking => ({
    type: SALES_SCREEN_SEARCH_MARK,
    mark: payload,
});

export const setSalesScreenAccountManagers = (payload: string[]): salesScreenAccountManagers => ({
    type: SALES_SCREEN_ACCMANAGERS,
    list: payload,
});

export const setSalesScreenDataUpdate = (dataKey: string, dataValue: SalesQueryDataCache<GetSalesScreens>): salesScreenDataUpdate => ({
    type: SALES_SCREEN_DATA_UPDATE,
    dataKey,
    dataValue
});

export const triggerSalesBuyerCapDetailTrigger = (buyer: string, mark: string, date: string, vertical: number, subVertical: number): salesBuyerCapDetailMarking => ({
    type: SALES_BUYER_CAP_DETAILS_TRIGGER,
    buyer,
    mark,
    date,
    vertical,
    subVertical
});

export const triggerSalesBuyerCapDetailUpdate = (buyerName: string, data?: GetBuyerSalesCapDetails): salesBuyerCapDetailUpdate => ({
    type: SALES_BUYER_CAP_DETAILS_UPDATE,
    buyer: buyerName,
    data
});

export default (state:salesScreenSearchStateProps | null = null, action: any): salesScreenSearchStateProps => {
    const storage = getStorage();
    const storedState = (storage && storage.getItem(STORE_KEY)) || false;

    let returnState = state || initialSalesScreenState;
    if(storedState && state == null){
        try { // in case that stored value is malformed JSON
            returnState = JSON.parse(storedState);
        } catch (error){}
    }

    returnState.loadedBuyerSalesCapacities = returnState.loadedBuyerSalesCapacities || {};

    switch (action.type) {
        case SALES_SCREEN_SEARCH_SAVE:
            returnState = { ...returnState, ...action.payload };
            break;
        case SALES_SCREEN_SEARCH_MARK:
            returnState = { ...returnState, lastLoadedMark: action.mark };
            break;
        case SALES_SCREEN_ACCMANAGERS:
            returnState = { ...returnState, accountManagers: action.list };
            break;
        case SALES_SCREEN_DATA_UPDATE:
            returnState = { 
                ...returnState,
                lastLoadedData: {
                    ...returnState.lastLoadedData,
                    [action.dataKey]: action.dataValue
                }
            };
            break;
        case SALES_BUYER_CAP_DETAILS_TRIGGER:
            returnState = { 
                ...returnState,
                loadedBuyerSalesCapacities: {
                    ...(returnState.loadedBuyerSalesCapacities || {}),
                    [action.buyer]: {
                        ...returnState.loadedBuyerSalesCapacities[action.buyer],
                        mark: action.mark,
                        date: action.date,
                        vertical: action.vertical,
                        subVertical: action.subVertical
                    }
                }
            };
            break;
        case SALES_BUYER_CAP_DETAILS_UPDATE:
            returnState = { 
                ...returnState,
                loadedBuyerSalesCapacities: {
                    ...(returnState.loadedBuyerSalesCapacities || {}),
                    [action.buyer]: {
                        ...returnState.loadedBuyerSalesCapacities[action.buyer],
                        data: action.data,
                        updatedAt: DateTime.now().toMillis(),
                    }
                }
            };
            break;
        default:
    }

    storage && storage.setItem(STORE_KEY, JSON.stringify(returnState));

    return returnState;
};