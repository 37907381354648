
export interface LoggedInUserProps {
  userData?: any;
}

interface LoggedInUserUpdateSignal {
  type: string;
  payload: {
    userData: any;
  };
}

const initialLoggedinUserState:LoggedInUserProps = {
  userData: undefined,
}

const UPDATE_LOGGEDIN_USER_STATE = "UPDATE_LOGGEDIN_USER_STATE";

export const updateLoggedInUser = (userData:any):LoggedInUserUpdateSignal => ({
  type: UPDATE_LOGGEDIN_USER_STATE,
  payload: {
    ...userData
  },
});

export default (state = initialLoggedinUserState, action:LoggedInUserUpdateSignal):LoggedInUserProps => {
  switch (action.type) {
    case UPDATE_LOGGEDIN_USER_STATE:
      const result = { ...state, userData: action.payload };
      return result;
    default:
      return state;
  }
};