import React from "react";
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./src/apollo/client";
import { ToastContainer } from "react-toastify";
import { rootReducer } from './src/state';
import "react-toastify/dist/ReactToastify.css";
// import "normalize.css";

const activeEnv = process.env.GATSBY_BRANCH || "development";

const createStore = () => reduxCreateStore(rootReducer, activeEnv === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ToastContainer />
      <Provider store={createStore()}>{element}</Provider>
    </ApolloProvider>
  );
};
