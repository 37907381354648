// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buyergroups-tsx": () => import("./../../../src/pages/buyergroups.tsx" /* webpackChunkName: "component---src-pages-buyergroups-tsx" */),
  "component---src-pages-buyers-tsx": () => import("./../../../src/pages/buyers.tsx" /* webpackChunkName: "component---src-pages-buyers-tsx" */),
  "component---src-pages-contracts-tsx": () => import("./../../../src/pages/contracts.tsx" /* webpackChunkName: "component---src-pages-contracts-tsx" */),
  "component---src-pages-errorsimulator-tsx": () => import("./../../../src/pages/errorsimulator.tsx" /* webpackChunkName: "component---src-pages-errorsimulator-tsx" */),
  "component---src-pages-general-settings-tsx": () => import("./../../../src/pages/general-settings.tsx" /* webpackChunkName: "component---src-pages-general-settings-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leadfielddata-tsx": () => import("./../../../src/pages/leadfielddata.tsx" /* webpackChunkName: "component---src-pages-leadfielddata-tsx" */),
  "component---src-pages-leads-tsx": () => import("./../../../src/pages/leads.tsx" /* webpackChunkName: "component---src-pages-leads-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ops-tsx": () => import("./../../../src/pages/ops.tsx" /* webpackChunkName: "component---src-pages-ops-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-pages-reqconstants-tsx": () => import("./../../../src/pages/reqconstants.tsx" /* webpackChunkName: "component---src-pages-reqconstants-tsx" */),
  "component---src-pages-salesscreen-tsx": () => import("./../../../src/pages/salesscreen.tsx" /* webpackChunkName: "component---src-pages-salesscreen-tsx" */),
  "component---src-pages-transformcode-tsx": () => import("./../../../src/pages/transformcode.tsx" /* webpackChunkName: "component---src-pages-transformcode-tsx" */),
  "component---src-pages-users-tsx": () => import("./../../../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */)
}

