
export interface ReportProps {
  [tableId:string]: any;
}

interface ReportStateSaveSignal {
  type: string;
  payload: {
    reportId:string;
    reportData: any;
  };
}

const initialMuiTableStates:ReportProps = {
  
}

const UPDATE_REPORT_STATE = "UPDATE_REPORT_STATE";

export const updateReport = (reportId:string, reportState:any):ReportStateSaveSignal => ({
  type: UPDATE_REPORT_STATE,
  payload: {
    reportId,
    reportData: reportState
  },
});

export default (state = initialMuiTableStates, action:ReportStateSaveSignal):ReportProps => {
  switch (action.type) {
    case UPDATE_REPORT_STATE:
      const result = { ...state, 
        [action.payload.reportId]: action.payload.reportData
      };
      return result;
    default:
      return state;
  }
};