import { DateTime } from "luxon";
import { GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup } from "../common/models/types/GetAllBuyerGroups";
import { GetBuyers_LDPConfigQueryGroup_Buyer } from "../common/models/types/getBuyers";
import { GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../common/models/types/GetSubVertical";
import { GetVertical_LDPConfigQueryGroup_Vertical } from "../common/models/types/GetVertical";

export interface DataCacheState {
  ActiveVerticals?: {
    data: (GetVertical_LDPConfigQueryGroup_Vertical | null)[] | null;
    time: number;
  },
  ActiveSubVerticals?: {
    data: (GetSubVertical_LDPConfigQueryGroup_SubVertical | null)[] | null;
    time: number;
  },
  Buyers?: {
    data: (GetBuyers_LDPConfigQueryGroup_Buyer | null)[] | null;
    time: number;
  },
  BuyerGroups?: {
    data: (GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup | null)[] | null;
    time: number;
  },
}

const initialDataCacheState: DataCacheState = {
  
};


const UPDATE_BUYERS = "B_UPDATE";
const UPDATE_VERTICALS = "V_UPDATE";
const UPDATE_SUBVERTICALS = "SV_UPDATE";
const UPDATE_BUYER_GROUPS = "BG_UPDATE";

interface DataCacheAction {
  type: string;
  payload: (GetBuyers_LDPConfigQueryGroup_Buyer | null)[] | 
    (GetVertical_LDPConfigQueryGroup_Vertical | null)[] | 
    (GetSubVertical_LDPConfigQueryGroup_SubVertical | null)[] | 
    (GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup | null)[] |
    null;
}

export const updateBuyers = (payload: (GetBuyers_LDPConfigQueryGroup_Buyer | null)[] | null): DataCacheAction => ({
  type: UPDATE_BUYERS,
  payload,
});

export const updateVerticals = (payload: (GetVertical_LDPConfigQueryGroup_Vertical | null)[] | null): DataCacheAction => ({
  type: UPDATE_VERTICALS,
  payload,
});

export const updateSubVerticals = (payload: (GetSubVertical_LDPConfigQueryGroup_SubVertical | null)[] | null): DataCacheAction => ({
  type: UPDATE_SUBVERTICALS,
  payload,
});

export const updateBuyerGroups = (payload: (GetAllBuyerGroups_LDPConfigQueryGroup_BuyerGroup | null)[] | null): DataCacheAction => ({
  type: UPDATE_BUYER_GROUPS,
  payload,
});

export default (state = initialDataCacheState, action:DataCacheAction):DataCacheState => {
  switch (action.type) {
    case UPDATE_BUYERS:
      return { ...state, 
        Buyers: {
          data: action.payload,
          time: DateTime.local().toMillis()
        }
      };
    case UPDATE_VERTICALS:
      return { ...state, 
        ActiveVerticals: {
          data: action.payload,
          time: DateTime.local().toMillis()
        }
      };
    case UPDATE_SUBVERTICALS:
      return { ...state, 
        ActiveSubVerticals: {
          data: action.payload,
          time: DateTime.local().toMillis()
        }
      };
    case UPDATE_BUYER_GROUPS:
      return { ...state, 
        BuyerGroups: {
          data: action.payload,
          time: DateTime.local().toMillis()
        }
      };
    
    default:
      return state;
  }
};