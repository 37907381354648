export interface buyerSectionStateProps {
  buyerRecordSaved: boolean;
  updateBuyerList: boolean;
  buyerTabs: Array<Object>;
  buyerMain: BuyerProps;
  buyerDetail: BuyerProps;
}

export interface BuyerDetailProps {
  buyerId: string;
  formIds: Array<string>;
}

export interface BuyerProps {
  [key: string]: BuyerDetailProps;
}

const initialFlagStates:buyerSectionStateProps = {
  buyerRecordSaved: false,
  updateBuyerList: false,
  buyerTabs: [],
  buyerMain: {},
  buyerDetail: {},
}

const BUYER_RECORD_SAVED  = "BUYER_RECORD_SAVED";
const BUYER_REFRESH_CARRIED  = "BUYER_REFRESH_CARRIED";

const BUYER_MAIN_FORMS_UPDATE = "BUYER_MAIN_FORMS_UPDATE";
const BUYER_DETAILS_FORMS_UPDATE = "BUYER_DETAILS_FORMS_UPDATE";

interface BuyerSaveSignal {
  type: string;
  payload?: any;
}

export const signalBuyerSaved = ():BuyerSaveSignal => ({
  type: BUYER_RECORD_SAVED
});

export const signalBuyerRefreshCarriedOut = ():BuyerSaveSignal => ({
  type: BUYER_REFRESH_CARRIED
});

export const updateBuyerMainForms = (props:BuyerDetailProps): BuyerSaveSignal => ({
  type: BUYER_MAIN_FORMS_UPDATE,
  payload: props,
});

export const updateBuyerDetailForms = (props:BuyerDetailProps): BuyerSaveSignal => ({
  type: BUYER_DETAILS_FORMS_UPDATE,
  payload: props,
});

export default (state = initialFlagStates, action:BuyerSaveSignal) => {
  switch (action.type) {
    case BUYER_RECORD_SAVED:
      return { ...state, buyerRecordSaved: true };
    case BUYER_REFRESH_CARRIED:
      return { ...state, buyerRecordSaved: false };
    case BUYER_MAIN_FORMS_UPDATE:
      return { ...state, buyerMain: {
                ...state.buyerMain, 
                  formIds: [...new Set([
                    ...(state.buyerMain.formIds ? state.buyerMain.formIds : []),
                    ...action.payload.formIds,
                  ])]
              }
            };
    
    case BUYER_DETAILS_FORMS_UPDATE:
      return { ...state, buyerDetail: {
                ...state.buyerDetail, 
                [action.payload.buyerId] : { //buyer-1: {c} 
                  ...state.buyerDetail[action.payload.buyerId],
                  formIds: [...new Set([
                    ...(state.buyerDetail[action.payload.buyerId] ? state.buyerDetail[action.payload.buyerId].formIds : []),
                    ...action.payload.formIds,
                  ])]
                },
              }
              };
    default:
      return state;
  }
};