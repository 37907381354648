import gql from "graphql-tag";

export const AUTHENTICATE = gql`
  query Authenticate($oneTimeCode: String!, $customRedirectURI: String) {
    Authenticate (OneTimeCode: $oneTimeCode, CustomRedirectURI: $customRedirectURI) {
      id_token
      access_token
      refresh_token
      expires_in
      token_type
      scope
    }
  }
`; 


export const REFRESH_LOGIN = gql`
  mutation RefreshLogin($refreshToken: String!) {
    RefreshLogin (RefreshToken: $refreshToken) {
      id_token
      access_token
      expires_in
      token_type
      scope
    }
  }
`; 
