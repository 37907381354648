import jwt from "jwt-decode";
import { Cookies } from "react-cookie";
import { Authenticate_Authenticate } from '../models/types/Authenticate';
import { REFRESH_LOGIN } from "../models/authenticate";
import {client} from "../../apollo/client";

export const isBrowser = typeof window !== `undefined`;

export const isLocalhost = isBrowser
  ? document.location.hostname === "localhost"
  : false;

export const cookieDomain = isLocalhost ? "localhost" : ".leaddeliveryplatform.com";

export const loginHref = isLocalhost
  ? "http://localhost:8101/login/"
  : process.env.GATSBY_LOGIN_URL ?? "";

export const tokenName = process.env.GATSBY_BRANCH + "-token";
export const refreshTokenName = process.env.GATSBY_BRANCH + "-refresh-token";

export const getToken = () => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  const token = cookies.get(tokenName);
  return token;
};

export const getRefreshToken = () => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  const token = cookies.get(refreshTokenName);
  return token;
};

export const setToken = (token: string, expiresIn?:number) => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  cookies.set(tokenName, token, {
    domain: cookieDomain,
    path: '/'
  });

  if(expiresIn){
    cookies.set(`${tokenName}Expiry`, expiresIn, {
      domain: cookieDomain,
      path: '/'
    });
  }
};

export const setRefreshToken = (token: string) => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  cookies.set(refreshTokenName, token, {
    domain: cookieDomain,
    path: '/'
  });
};

export const getTokenExpiry = () => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  const token = cookies.get(`${tokenName}Expiry`) || 0;
  return Number(token);
};

const clearStore = () => {
  const cookies = new Cookies(isBrowser ? document.cookie : "");
  cookies.remove(tokenName, {
    domain: cookieDomain,
    path: '/'
  });

  cookies.remove(refreshTokenName, {
    domain: cookieDomain,
    path: '/'
  });

  cookies.remove(`${tokenName}Expiry`, {
    domain: cookieDomain,
    path: '/'
  });

  sessionStorage.removeItem('GoogleUserMeta');
};

export const handleLogin = (auth: Authenticate_Authenticate) => {
  if (!isBrowser) return false;
  if(auth){
    setToken(auth?.access_token || "");
    setRefreshToken(auth?.refresh_token || "");
    return true;
  }
};


export const refreshAuthentication = () => {
  const refreshToken = getRefreshToken();
  let refreshLogin = client.mutate({mutation: REFRESH_LOGIN, variables: {
      refreshToken: refreshToken
    }}).then((response) => {
      const newToken = response?.data?.RefreshLogin?.id_token;
      if(newToken){
        console.log("New token received");
        setToken(newToken);
        return true;
      }else{
        console.log("refreshAuthentication unable to refresh Token", response);
        logout(() => {
          document.location.href = loginHref;
        });
      }
    })
    .catch(err => {
      console.log("ERROR refreshAuthentication", err);
    });
  return refreshLogin;
}

export const isLoggedIn = () => {
  if (!isBrowser) return false;
  try {
    const token = getToken();
    const decToken = jwt(token);
    const timeRem = decToken.exp - Math.round(Date.now() / 1000);

    if (timeRem > 300) return true;
    else {
      //clearStore();
      return false;
    }
  } catch(error) {    
    //clearStore();
     return false;
  }
};

export const logout = (callback: any) => {
  if (!isBrowser) return;
  console.log(`Logging out`);    
  clearStore();
  callback();
};

export const auth = {
  isBrowser,
  handleLogin,
  isLoggedIn,
  logout,
  refreshAuthentication
};
