
export interface GeneralProps {
  [recordId:string]: any;
}

interface GeneralStateSaveSignal {
  type: string;
  payload: {
    recordId:string;
    recordData: any;
  };
}

const initialMuiTableStates:GeneralProps = {
  
}

const UPDATE_RECORD_STATE = "UPDATE_RECORD_STATE";

export const updateRecordData = (reportId:string, reportState:any):GeneralStateSaveSignal => ({
  type: UPDATE_RECORD_STATE,
  payload: {
    recordId: reportId,
    recordData: reportState
  },
});

export default (state = initialMuiTableStates, action:GeneralStateSaveSignal):GeneralProps => {
  switch (action.type) {
    case UPDATE_RECORD_STATE:
      const result = { ...state, 
        [action.payload.recordId]: action.payload.recordData
      };
      return result;
    default:
      return state;
  }
};