import { ReportField } from "../types/reports";

const reportFormInitialState:ReportField<any> | null = null;
const REPORT_FIELDS_UPDATED  = "REPORT_FIELDS_UPDATED";

interface ReportFormFieldUpdate {
  type: "REPORT_FIELDS_UPDATED";
  formState: ReportField<any>;
}

export const reportsFormUpdated = (newFormState:ReportField<any>):ReportFormFieldUpdate => ({
  type: REPORT_FIELDS_UPDATED,
  formState: newFormState
});

export default (state = reportFormInitialState, action:ReportFormFieldUpdate) => {
  switch (action.type) {
    case REPORT_FIELDS_UPDATED:
      return action.formState;
    default:
      return state;
  }
};