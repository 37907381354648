import gql from "graphql-tag";

export const defaults = {};

// export const typeDefs = gql`
  // type Query {
  //   title: String
  // }
// `;

export const resolvers = {};
