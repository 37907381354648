const initialFlagStates = {
  buyerGroupRecordSaved: false
}

const BUYERGROUP_RECORD_SAVED  = "BUYERGROUP_RECORD_SAVED";
const BUYERGROUP_REFRESH_CARRIED  = "BUYERGROUP_REFRESH_CARRIED";

interface BuyerGroupSaveSignal {
  type: string;
}

export const signalBuyerGroupSaved = ():BuyerGroupSaveSignal => ({
  type: BUYERGROUP_RECORD_SAVED
});

export const signalBuyerGroupRefreshCarriedOut = ():BuyerGroupSaveSignal => ({
  type: BUYERGROUP_REFRESH_CARRIED
});

export default (state = initialFlagStates, action:BuyerGroupSaveSignal) => {
  switch (action.type) {
    case BUYERGROUP_RECORD_SAVED:
      return { ...state, buyerGroupRecordSaved: true };
    case BUYERGROUP_REFRESH_CARRIED:
      return { ...state, buyerGroupRecordSaved: false };
    default:
      return state;
  }
};