import { DateTime } from "luxon";
import { GetOpsScreen } from "../common/models/types/GetOpsScreen";
import { LDPUIDateFormat } from "../common/utils/date";
import { getStorage } from "../common/utils/storage";

export interface opsScreenSearchStateProps {
    endDate: string;
    startDate: string;
    buyerName: string;
    subVerticalId: number;
    verticalId: number;
    lastUpdatedAt?: string;
    lastLoadedData?: GetOpsScreen;
    opsScreenSearchId?: string;
    timeSelectionMode: string;
    fetchEDW?: boolean;
}3

const initialOpsScreenState: opsScreenSearchStateProps = {
    endDate: '', //save unmodified startDate
    startDate: '', //save unmodified endDate
    buyerName: '',
    subVerticalId: 0,
    verticalId: 0,
    timeSelectionMode: '',
    opsScreenSearchId: '0',
    fetchEDW: false,
};

const OPSSCREEN_SEARCH_SAVE = "OPSSCREEN_SEARCH_SAVE";
const OPSSCREEN_STORE_DATA = "STORE_LOADED_DATA";

const STORE_KEY = `${process.env.GATSBY_BRANCH}-optsScreenSearchStateProps`;

interface opsScreenSearchAction {
    type: string;
    payload?: opsScreenSearchStateProps;
}

interface opsScreenStoreAction {
    type: string;
    payload?: GetOpsScreen;
}

export const saveOpsScreenSearch = (payload: opsScreenSearchStateProps): opsScreenSearchAction => ({
    type: OPSSCREEN_SEARCH_SAVE,
    payload,
});

export const saveOpsScreenData = (payload?: GetOpsScreen): opsScreenStoreAction => ({
    type: OPSSCREEN_STORE_DATA,
    payload,
});

export default (state:opsScreenSearchStateProps | null = null, action: any): opsScreenSearchStateProps => {
    const storage = getStorage();
    const storedState = (storage && storage.getItem(STORE_KEY)) || false;

    // console.log(state);

    let returnState = state || initialOpsScreenState;
    if(storedState && state == null){
        try { // in case that stored value is malformed JSON
            returnState = JSON.parse(storedState);
        } catch (error){}
    }

    switch (action.type) {
        case OPSSCREEN_SEARCH_SAVE:
            returnState = { ...returnState, ...action.payload };
            break;

        case OPSSCREEN_STORE_DATA:
            returnState = { ...returnState, 
                lastLoadedData: action.payload,
                lastUpdatedAt: DateTime.now().toFormat('h:mm a'),
            };
            break;

        default:
            break;
    }

    storage && storage.setItem(STORE_KEY, JSON.stringify(returnState));

    return returnState;
};