import { TableState } from "../components/LDPUIDataTable";

interface ExtendedMuiTableState extends TableState {
  others?: {
    [key: string]: any;
  }
}

export interface MuiTableStateProps {
  [tableId:string]: ExtendedMuiTableState;
}

interface MuiTableStateSaveSignal {
  type: string;
  payload: {
    tableId:string;
    tableState: ExtendedMuiTableState;
  };
}

const initialMuiTableStates:MuiTableStateProps = {
  
}

const MUI_TABLE_LIST_STATE = "MUI_TABLE_LIST_STATE";
const MUI_TABLE_FILTER_STATE = "MUI_TABLE_FILTER_STATE";
const MUI_TABLE_SEARCHTEXT_STATE = "MUI_TABLE_SEARCHTEXT_STATE";
export const updateMUITableState = (tableId:string, listState:ExtendedMuiTableState):MuiTableStateSaveSignal => ({
  type: MUI_TABLE_LIST_STATE,
  payload: {
    tableId,
    tableState: listState
  },
});

export const updateMUITableFilterState = (tableId:string, listState:TableState):MuiTableStateSaveSignal => ({
  type: MUI_TABLE_FILTER_STATE,
  payload: {
    tableId,
    tableState: listState
  },
});

export const updateMUITableSearchTextState = (tableId:string, searchText:string):MuiTableStateSaveSignal => ({
  type: MUI_TABLE_SEARCHTEXT_STATE,
  payload: {
    tableId,
    tableState: {
      searchText: searchText
    }
  },
});

export default (state = initialMuiTableStates, action:MuiTableStateSaveSignal):MuiTableStateProps => {
  let result = {};
  switch (action.type) {
    case MUI_TABLE_LIST_STATE:
      result = { ...state, 
        [action.payload.tableId]: action.payload.tableState
      };
      return result;
    case MUI_TABLE_FILTER_STATE:
      result = { ...state, 
        [action.payload.tableId]: {
          ...state[action.payload.tableId],
          ...action.payload.tableState,
        }
      };

      return result;
    case MUI_TABLE_SEARCHTEXT_STATE:
      result = { ...state, 
        [action.payload.tableId]: {
          ...state[action.payload.tableId],
          searchText: action.payload.tableState.searchText
        }
      };
      return result;
    default:
      return state;
  }
};