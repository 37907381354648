const initialFlagStates = {
  leadSearchForm: {}
}
const LEAD_SEARCH_FORM_SAVE = "LEAD_SEARCH_FORM_SAVE";

interface LeadSearchFormSaveSignal {
  type: string;
  payload?: any;
}

export const signalLeadSearchFormSave = (props: any):LeadSearchFormSaveSignal => ({
  type: LEAD_SEARCH_FORM_SAVE,
  payload: props,
});

export default (state = initialFlagStates, action: LeadSearchFormSaveSignal) => {
  switch (action.type) {
    case LEAD_SEARCH_FORM_SAVE:
      return { ...state, leadSearchForm: action.payload };
    default:
      return state;
  }
};