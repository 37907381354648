export const getStorage = ():Storage | false => {
  try {
    return localStorage;
  } catch(err) {}

  try {
    return sessionStorage;
  } catch(err) {}

  return false;
}