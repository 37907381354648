import { ReactNode } from "react";

export interface TabProps {
  title: string | ReactNode;
  tabId: string;
  content: ReactNode;
  closeTab?: Function | boolean; //override default close tab callback
  permalink?: string;
}

export interface tabContainerStateProps {
  [key: string]: {
    tabs:TabProps[];
    tabFocus: number;
  };
}
export interface tabsSectionStateProps {
  tabContainers: tabContainerStateProps;
}

const initialTabContainersState: tabsSectionStateProps = {
  tabContainers: {},
};


const TAB_CONTAINERS_UPDATE = "TAB_CONTAINERS_UPDATE";
const TAB_CONTAINERS_DELETE = "TAB_CONTAINERS_DELETE";
const TAB_ADD = "TAB_ADD";
const TAB_DELETE = "TAB_DELETE";

interface tabAction {
  type: string;
  payload?: any;
}

export const updateTabContainers = (payload: tabContainerStateProps): tabAction => {  
  return {
    type: TAB_CONTAINERS_UPDATE,
    payload,
  }  
};

export const deleteTabContainers = (payload: string): tabAction => ({
  type: TAB_CONTAINERS_DELETE,
  payload,
});

export const addTab = (payload: any): tabAction => ({
  type: TAB_ADD,
  payload,
});

export const deleteTab = (payload: any): tabAction => ({
  type: TAB_DELETE,
  payload,
});

export default (state = initialTabContainersState, action:tabAction):tabsSectionStateProps => {
  switch (action.type) {
    case TAB_CONTAINERS_UPDATE:      
      return { ...state, tabContainers: { ...state.tabContainers, ...action.payload} };

    case TAB_CONTAINERS_DELETE:
      if(typeof(state.tabContainers[action.payload]) !== "undefined") delete state.tabContainers[action.payload];
      return state;
    
    case TAB_ADD:
      if(typeof(state.tabContainers[action.payload.tabContainer]) !== "undefined"){
        const tabFocus = state.tabContainers[action.payload.tabContainer].tabs.findIndex((obj: { tabId: string; }) => obj.tabId === action.payload.tab.tabId);
        if(tabFocus > 0){
          return {...state, tabContainers: { ...state.tabContainers, [action.payload.tabContainer]: {...state.tabContainers[action.payload.tabContainer], tabFocus: tabFocus}} };
        }
        const newTabs = [...state.tabContainers[action.payload.tabContainer].tabs, action.payload.tab];
        const finalState = {...state, tabContainers: { ...state.tabContainers, [action.payload.tabContainer]: {tabFocus: newTabs.length, tabs: newTabs}} };
        return finalState;
        // return {...state, tabContainers: { ...state.tabContainers, [action.payload.tabContainer]: {tabFocus: newTabs.length, tabs: newTabs}} };
      }
      return state;

    case TAB_DELETE:
      if(typeof(state.tabContainers[action.payload.tabContainer]) !== "undefined"){
        const newTabs = state.tabContainers[action.payload.tabContainer].tabs.filter((tab)=>tab.tabId !== action.payload.tabId);
        return {...state, tabContainers: { ...state.tabContainers, [action.payload.tabContainer]: {tabFocus: 0, tabs:newTabs}} };
      }
      return state;
    
    default:
      return state;
  }
};