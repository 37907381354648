export interface ContractRequestStateProps {
  contractRequestBodyRecordSaved: boolean,
};

interface ActionProps {
  type: string;
  payload?: Object;
};

const initialStates:ContractRequestStateProps = {
  contractRequestBodyRecordSaved: false,
};

const CONTRACT_REQUEST_BODY_RECORD_SAVED = "CONTRACT_REQUEST_BODY_RECORD_SAVED";
const CONTRACT_REQUEST_BODY_REFRESH_CARRIED = "CONTRACT_REQUEST_BODY_REFRESH_CARRIED";

export const signalContractRequestBodySaved = (): ActionProps => ({
  type: CONTRACT_REQUEST_BODY_RECORD_SAVED
});

export const signalContractRequestBodyRefreshCarriedOut = ():ActionProps => ({
  type: CONTRACT_REQUEST_BODY_REFRESH_CARRIED,
});


export default (state = initialStates, action:ActionProps):ContractRequestStateProps => {
  switch (action.type) {
    case CONTRACT_REQUEST_BODY_RECORD_SAVED:
      return { ...state, contractRequestBodyRecordSaved: true };
    case CONTRACT_REQUEST_BODY_REFRESH_CARRIED:
      return { ...state, contractRequestBodyRecordSaved: false };

    default:
      return state;
    }
};