import { TableListState } from "../types/common";

export interface contractSectionStateProps {
  contractRecordSaved: boolean;
  updateContractList: boolean;
  contractTabs: Array<Object>;
  contractMain: ContractProps;
  contractDetail: ContractProps;
  contractListState?: TableListState;
}

export interface ContractDetailProps {
  contractId: string;
  formIds: Array<string>;
}

export interface ContractProps {
  [key: string]: ContractDetailProps;
}

const initialFlagStates:contractSectionStateProps = {
  contractRecordSaved: false,
  updateContractList: false,
  contractTabs: [],
  contractMain: {},
  contractDetail: {},
}

const CONTRACT_RECORD_SAVED = "CONTRACT_RECORD_SAVED";
const CONTRACT_REFRESH_CARRIED = "CONTRACT_REFRESH_CARRIED";

const UPDATE_CONTRACT_LIST = "UPDATE_CONTRACT_LIST";
const CONTRACT_LIST_UPDATED = "CONTRACT_LIST_UPDATED";

const CONTRACT_TABS_SAVE = "CONTRACT_TABS_SAVE";

const CONTRACT_MAIN_FORMS_UPDATE = "CONTRACT_MAIN_FORMS_UPDATE";
const CONTRACT_DETAILS_FORMS_UPDATE = "CONTRACT_DETAILS_FORMS_UPDATE";

interface ContractSaveSignal {
  type: string;
  payload?: any;
}

export const signalContractSaved = (): ContractSaveSignal => ({
  type: CONTRACT_RECORD_SAVED
});

export const signalContractRefreshCarriedOut = ():ContractSaveSignal => ({
  type: CONTRACT_REFRESH_CARRIED,
});

export const updateContractList = ():ContractSaveSignal => ({
  type: UPDATE_CONTRACT_LIST,
});

export const contractListUpdated = ():ContractSaveSignal => ({
  type: CONTRACT_LIST_UPDATED,
});

export const saveContractTabs = (tabs:any): ContractSaveSignal => ({
  type: CONTRACT_TABS_SAVE,
  payload: tabs
});

export const updateContractMainForms = (props:ContractDetailProps): ContractSaveSignal => ({
  type: CONTRACT_MAIN_FORMS_UPDATE,
  payload: props,
});

export const updateContractDetailForms = (props:ContractDetailProps): ContractSaveSignal => ({
  type: CONTRACT_DETAILS_FORMS_UPDATE,
  payload: props,
});

export default (state = initialFlagStates, action:ContractSaveSignal):contractSectionStateProps => {
  switch (action.type) {
    case CONTRACT_RECORD_SAVED:
      return { ...state, contractRecordSaved: true, updateContractList: true };
    case CONTRACT_REFRESH_CARRIED:
      return { ...state, contractRecordSaved: false };

    case UPDATE_CONTRACT_LIST:
      return { ...state, updateContractList: true };
    case CONTRACT_LIST_UPDATED:
      return { ...state, updateContractList: false };
      
    case CONTRACT_TABS_SAVE:
      return { ...state, contractTabs: action.payload };

    case CONTRACT_MAIN_FORMS_UPDATE:
      return { ...state, contractMain: {
                ...state.contractMain, 
                  formIds: [...new Set([
                    ...(state.contractMain.formIds ? state.contractMain.formIds : []),
                    ...action.payload.formIds,
                  ])]
              }
             };

    case CONTRACT_DETAILS_FORMS_UPDATE:
      return { ...state, contractDetail: {
                ...state.contractDetail, 
                [action.payload.contractId] : { //contract-1: {c} 
                  ...state.contractDetail[action.payload.contractId],
                  formIds: [...new Set([
                    ...(state.contractDetail[action.payload.contractId] ? state.contractDetail[action.payload.contractId].formIds : []),
                    ...action.payload.formIds,
                  ])]
                },
              }
             };

    default:
      return state;
  }
};