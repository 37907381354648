import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, split } from "apollo-link";
import customFetch from "isomorphic-fetch";
import { defaults, resolvers } from "./store";
import { Cookies } from "react-cookie";
import {
  isBrowser,
  isLocalhost,
  loginHref,
  cookieDomain,
  tokenName,
  getRefreshToken,
  setRefreshToken,
  setToken,
  getToken,
  getTokenExpiry,
  isLoggedIn,
  logout
} from "../common/utils/auth";
import { toast, ToastId } from "react-toastify";

const cache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: (object: any) => object.__typename + object.id,
});

const authLink = new ApolloLink((op: any, forward: any) => {
  const cookies = new Cookies(document.cookie);
  op.setContext(({ headers }: any) => {
    const newHeaders = { headers: { ...headers } };
    const refreshToken = getRefreshToken();

    if (isLoggedIn()) {
      const token = isBrowser && cookies.get(tokenName);
      newHeaders.headers.Token = token; // IN LDP, TOKEN IS BEING USED

      //refresh token and expiry check
      if (!!refreshToken && refreshToken !== "") {
        const tokenExpiry = Number(getTokenExpiry());
        const timeNow = Date.now() / 1000;
        if (timeNow >= tokenExpiry ) {
          newHeaders.headers.RefreshToken = refreshToken;
        }
      }

    } else {
      if (!!refreshToken && refreshToken !== "") {
        newHeaders.headers.RefreshToken = refreshToken;
      }

    }

    return newHeaders;
  });

  return forward(op);
});

const httpLink = new HttpLink({
  uri: process.env.GATSBY_API_URL,
  credentials: "same-origin",
  fetch: customFetch,

});

const refreshTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const refreshIdToken = operation.getContext().response.headers.get("refresh_id_token");
    if (refreshIdToken) {
      //console.log("received refreshed token, updating");
      setToken(refreshIdToken, (Date.now() / 1000) + (Number(operation.getContext().response.headers.get("refresh_expires_in")) || 1800));
    }

    return response;
  });
}).concat(httpLink);

const logoutRedirect = (cookies: Cookies) => {
  logout(() => {

    cookies.set("prevPage", document.location.href, {
      domain: cookieDomain,
    });

    document.location.href = loginHref;
  });
}

const IgnoreErrorToasting = [{
  operationName: "PingTest",
  error: "Variable '$pingInput' is invalid. Received a null input for a non-null variable."
},
{
  operationName: "MergeBuyerMonetaryCapacityConfig",
  error: "Err: Violation of UNIQUE KEY constraint"
},
{
  operationName: "MergeBuyerMonetaryCapacityConfig",
  error: "Exception of type 'GraphQL.ExecutionError' was thrown."
},
];

const CustomErrorToasting = [{
  operationName: "SaveContractResponseParsing",
  error: "Cannot insert duplicate key row in object",
  message: "Could not save: Response mapping already exists."
},
{
  operationName: "SaveTransformCode",
  error: "Cannot have duplicate values",
  message: "Could not save: Transform for this field already exists."
},
{
  operationName: "SaveContractRequestSchema",
  error: "Cannot insert duplicate key row in object 'dbo.ContractRequestSchema' with unique index 'UX_ContractRequestSchema_ContractId_IsActive_Ping'",
  message: 'Duplicate Error: Could not Save Contract Request Body with the same ContractId, IsActive, IsPing values. Please correct and try again.',
  useErrorTemplate: false, 
}
]

let lastToastId:ToastId | undefined = undefined;
let clearToast:number | null = null;
const clearToastId = () => {
  lastToastId = undefined;
};

const showApolloError = (message:string, useTemplate:boolean = true) => {
  toast.dismiss();
  lastToastId = toast.error(!useTemplate ? message : `Error: ${message} Please contact support!`, {
    onClose: clearToastId
  });

  //auto clear toast ID after some sec
  if(clearToast) clearTimeout(clearToast);
  clearToast = setTimeout(clearToastId, 1000);
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(e => {
      console.log(e.response, JSON.stringify(e.networkError));
      console.log(e);

      if(e.graphQLErrors && e.graphQLErrors.length > 0){
        e.graphQLErrors.forEach(({ message, locations, path }) => {
          const cookies = new Cookies(document.cookie);

          if (message === "Unauthorized") {
            logoutRedirect(cookies);
          }

          let toastingError = true;
          for(const iet of IgnoreErrorToasting){
            if (iet.operationName === e?.operation?.operationName && message.includes(iet.error)){
              toastingError = false;
              break;
            }
          };

          for(const cet of CustomErrorToasting){
            if (cet.operationName === e?.operation?.operationName && message.includes(cet.error)){
              if( typeof cet.useErrorTemplate === 'boolean' ) showApolloError(cet.message, cet.useErrorTemplate);
              else showApolloError(cet.message);
              toastingError = false;
              break;
            }
          };

          if ( toastingError && !lastToastId ){
            showApolloError(message);
          }
          
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
  
      if (e.networkError) console.log(`[Network error]: ${e.networkError}`);

    }),
    authLink,
    // link,
    refreshTokenLink
  ]),
  cache,
  // typeDefs,
  resolvers,
});

cache.writeData({ data: defaults });
