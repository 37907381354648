export interface FormDataProps {
  isSaving: boolean;
  isDocked: boolean;
  isModal: boolean;
  isLoading: boolean;
  isTriggered: boolean;
  isLastOpenedDocked: boolean;
  isClosed: boolean;
  formId: string;
  formComponent?: any;
  formData: any;
  formTitle: string | undefined;
  formIcon?: any;
  formProps?: any;
}

export interface FormContainersProps {
  [key: string]: FormDataProps;
}

export interface FormStateProps {
  formContainers: FormContainersProps;
}

export interface FormMethodProps {
  formId: string;
  formTitle?: string;
  formIcon?: any;
  formData?: any;
  formComponent?: any;
  formProps?: any;
}

interface ActionProps {
  type: string;
  formId: string;
  formTitle?: string;
  formIcon?: any;
  payload?: any;
  formComponent?: any;
  formProps?: any;
}


const initialFormContainersState: FormStateProps = {
  formContainers: {},
};

const initialFormData:FormDataProps = {
  isSaving: false,
  isDocked: false,
  isModal: false,
  isLoading: false,
  isTriggered: false,
  isLastOpenedDocked: false,
  isClosed: false,
  formId: '',
  formComponent: undefined,
  formData: {},
  formTitle: '',
  formProps: {},
}

const DOCK_FORM = "DOCK_FORM";
const TOGGLE_FORM_DOCKING = "TOGGLE_FORM_DOCKING";
const FORM_TRIGGERED = "FORM_TRIGGERED";
const OPEN_FORM = "OPEN_FORM";
const UPDATE_FORM = "UPDATE_FORM";
const RESET_FORM_DATA = "RESET_FORM_DATA";

const FORM_CLOSED = "FORM_CLOSED";


export const dockForm = (props:FormMethodProps):ActionProps => ({
  type: DOCK_FORM,
  formId: props.formId,
  formTitle: props.formTitle,
  payload: props.formData,
  formComponent: props.formComponent,
  formProps: props.formProps,
});
export const openForm = (props:FormMethodProps):ActionProps => ({
  type: OPEN_FORM,
  formId: props.formId,
  formTitle: props.formTitle,
  formIcon: props.formIcon,
  payload: props.formData,
  formComponent: props.formComponent,
  formProps: props.formProps,
});
export const toggleForm = (props:FormMethodProps):ActionProps => ({
  type: TOGGLE_FORM_DOCKING,
  formId: props.formId,
  payload: props.formData,
});
export const triggeredForm = (props:FormMethodProps):ActionProps => ({
  type: FORM_TRIGGERED,
  formId: props.formId,
});
export const closedForm = (props:FormMethodProps):ActionProps => ({
  type: FORM_CLOSED,
  formId: props.formId,
});
export const updateForm = (props:FormMethodProps):ActionProps => ({
  type: UPDATE_FORM,
  formId: props.formId,
  payload: props.formData,
});
export const resetForm = (props:FormMethodProps):ActionProps => ({
  type: RESET_FORM_DATA,
  formId: props.formId,
});


export default (state = initialFormContainersState, action:ActionProps):FormStateProps => {
  switch (action.type) {
      
    case DOCK_FORM:
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  isDocked: true, 
                  isModal: false, 
                  formData: action.payload, 
                  isTriggered: true,
                  isLastOpenedDocked: true,
                  formId: action.formId,
                  formTitle: action.formTitle,
                  formComponent: action.formComponent,
                  formProps: action.formProps,
                }
              }
            };
    case FORM_TRIGGERED: 
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  isTriggered: false,
                }
              }
            };
    case OPEN_FORM:
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  isDocked: false, 
                  isModal: true, 
                  formData: action.payload, 
                  isTriggered: true,
                  isLastOpenedDocked: false,
                  formId: action.formId,
                  formTitle: action.formTitle,
                  formIcon: action.formIcon,
                  formComponent: action.formComponent,
                  formProps: action.formProps,
                }
              }
            };
    case TOGGLE_FORM_DOCKING: 
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  isTriggered: !state.formContainers[action.formId].isTriggered,
                  isModal: !state.formContainers[action.formId].isModal, 
                  isDocked: !state.formContainers[action.formId].isDocked, 
                  isLastOpenedDocked: !state.formContainers[action.formId].isLastOpenedDocked,
                  formData: action.payload, 
                }
              }
            };
    case UPDATE_FORM:
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  formData: action.payload, 
                }
              }
            };
    case RESET_FORM_DATA:
      return { ...state, 
                formContainers:{
                  ...state.formContainers,
                  [action.formId]:{
                    ...initialFormData,
                    isLastOpenedDocked: state.formContainers[action.formId].isLastOpenedDocked,
                    isClosed: true,
                  }
                }
              };
    case FORM_CLOSED: 
      return { ...state, 
              formContainers:{
                ...state.formContainers,
                [action.formId]:{
                  ...state.formContainers[action.formId],
                  isClosed: false,
                }
              }
            };
    
    default:
      return state;
    }
};